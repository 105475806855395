import { removePopover } from './module/global.js';

document.addEventListener("mousedown", removePopover);

document.addEventListener("wheel", removePopover);

// ウインドウサイズを変更したらオフキャンバスを隠す
window.addEventListener('resize', () => {
    hideOffcanvas();
});

// オフキャンバスを隠す
function hideOffcanvas()
{
    const offcanvas = bootstrap.Offcanvas.getInstance('#globalOffcanvas');
    if (offcanvas) {
        offcanvas.hide();
    }
}

// オフキャンバス表示時に広告を挿入する
document.getElementById('globalOffcanvas').addEventListener('shown.bs.offcanvas', () => {
    const adScript = document.createElement('script');
    adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    adScript.async = true;
    adScript.onload = () => {
        // AdSense広告を生成
        const adDiv = document.createElement('ins');
        adDiv.className = "adsbygoogle";
        adDiv.style.display = "inline-block";
        adDiv.style.width = "160px";
        adDiv.style.height = "600px";
        adDiv.setAttribute("data-ad-client", "ca-pub-4204147268077756");
        adDiv.setAttribute("data-ad-slot", "4239293217");
        adDiv.setAttribute("data-overlays", "bottom");
        document.getElementById('globalOffcanvasAd').appendChild(adDiv);

        // 広告をレンダリング
        (adsbygoogle = window.adsbygoogle || []).push({});
    };
    document.getElementById('globalOffcanvasAd').appendChild(adScript);
}, {once: true});

// ホーム画面へ広告挿入
document.addEventListener('DOMContentLoaded', () => {
    // IntersectionObserverのコールバック関数
    const onIntersection = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const adRow = entry.target;

                // 要素の幅を取得
                const adRowWidth = adRow.offsetWidth;

                // AdSense広告を挿入する関数
                const insertAdSense = (container, adSlot) => {
                    const adScript = document.createElement('script');
                    adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
                    adScript.async = true;
                    adScript.onload = () => {
                        const adIns = document.createElement('ins');
                        adIns.className = 'adsbygoogle';
                        adIns.style.display = 'inline-block';
                        adIns.style.width = '336px';
                        adIns.style.height = '280px';
                        adIns.setAttribute('data-ad-client', 'ca-pub-4204147268077756');
                        adIns.setAttribute('data-ad-slot', adSlot);
                    adIns.setAttribute('data-overlays', 'bottom');
                        container.appendChild(adIns);
                        
                        // AdSenseの初期化
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    }
                    container.appendChild(adScript);
                };

                // AdManager広告を挿入する関数
                const insertAdManager = (container, id) => {
                    const adScript = document.createElement('script');
                    adScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
                    adScript.async = true;
                    adScript.onload = () => {
                        const adManager = document.createElement('div');
                        adManager.id = id;
                        adManager.style.width = '336px';
                        adManager.style.height = '280px';
                        container.appendChild(adManager);

                        googletag.cmd.push(function() { googletag.display(id); });
                    }
                    container.appendChild(adScript);
                };

                // 幅が700px以上の場合、広告を2つ挿入
                if (adRowWidth >= 700) {
                    insertAdSense(adRow, '6636434845'); // 広告ユニットID1
                    if (adRow.childElementCount < 2) {
                        insertAdSense(adRow, '6636434845'); // 広告ユニットID1
                    }
                    //insertAdManager(adRow, 'div-gpt-ad-1743168983282-0'); // 広告ユニットID2
                } else {
                    // 幅が700px未満の場合、広告を1つ挿入
                    if (adRow.childElementCount < 1) {
                        insertAdSense(adRow, '6636434845'); // 広告ユニットID1
                    }
                    //insertAdSense(adRow, '6636434845'); // 広告ユニットID1
                }

                // 監視を停止
                observer.unobserve(adRow);
            }
        });
    };

    // IntersectionObserverを初期化
    const observer = new IntersectionObserver(onIntersection, {
        root: null, // ビューポート
        threshold: [0, 0.1, 0.25], // 要素が10%見えたら処理
    });

    // すべての.ad-row要素を監視対象に追加
    const adRows = document.querySelectorAll('.ad-row');
    adRows.forEach(adRow => observer.observe(adRow));
});