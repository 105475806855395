import { createElementFromTwigTemplateData, setting, reloadPopover } from './module/global.js';
import { addNewsAll, createEmote } from './module/news.js';
import { addRow, updateRow, deleteRow } from './module/houchi.js';

const targets = [];

let conn = null;
let disconnectTimeout = null;

window.addEventListener('load', () => {
    // Realtime
    if (1 == setting("ws")) {
        const userId = document.getElementById('userid');
        if (userId && "value" in userId) {
            targets.push({name: userId.value, handle: onSocketMessage});
        }
    }

    // Chat
    if (1 == setting("chat")) {
        targets.push({name: "newsall", handle: onSocketMessageNewsAll});
    }

    // Houchi
    if ("/houchi" == location.pathname) {
        targets.push({name: "houchi", handle: onSocketMessageHouchi});
    }

    // websocket
    createConnection();
});

function createConnection() {
    if (!targets.length) {
      return;
    }

    conn = new autobahn.Connection({
        url: 'wss://' + process.env.WS_DOMAIN + '/websocket',
        realm: 'realm1',
    });

    conn.onopen = (session) => {
        targets.forEach((target) => {
            session.subscribe(target.name, target.handle);
        });
        console.log('OPEN(' + targets.map(target => target.name).join(',') + ')');
    };

    conn.open();
}

function closeConnection() {
    if (conn && conn.isConnected) {
        console.log('CLOSE(' + targets.map(target => target.name).join(',') + ')');
        conn.close();
        conn = null;
    }
}

document.addEventListener('visibilitychange', function () {
    // リアルタイム更新時のサウンド再生が有効であればwebsocket接続は切断しない
    if (setting("wsSound")) {
      return;
    }

    if (document.visibilityState === 'visible') {
        // タイマーがあればキャンセル
        if (disconnectTimeout) {
            clearTimeout(disconnectTimeout);
            disconnectTimeout = null;
        }

        // 再接続（必要な場合）
        if (!conn) {
            createConnection();
        }
    } else {
        // 30秒後に接続を切断
        disconnectTimeout = setTimeout(() => {
            closeConnection();
        }, 30000);
    }
});

// websocket Open
function onSocketMessage (args) {
    let msg = args[0];
    if ("top" == msg.title) {
      // 画面更新
      remakeTop(msg.data);
      // アニメーション（ロゴ）
      if (1 === msg.data.enemy_id) {
        startAnimation('.gbfdrop-icon', 'my-animation-poyopoyo');
      } else if (2 === msg.data.enemy_id) {
        startAnimation('.gbfdrop-icon', 'my-animation-poyooon');
      } else if (3 === msg.data.enemy_id) {
        startAnimation('.gbfdrop-icon', 'my-animation-spin-stretch');
      } else {
        startAnimation('.gbfdrop-icon', 'my-animation-poyopoyo');
      }
      // アニメーション・サウンド
      if (msg.data.drop) {
        if ('栄冠の指輪' == msg.data.drop) {
          startAnimation('.ring-down-silver .ring-down', 'my-animation-ring-down');
          startAnimation('.ring-down-silver', 'my-animation-ring-down-x');
          startSound('ring');
        } else if ('覇業の指輪' == msg.data.drop) {
          startAnimation('.ring-down-black .ring-down', 'my-animation-ring-down');
          startAnimation('.ring-down-black', 'my-animation-ring-down-x');
          startSound('ring');
        } else if ('至極の指輪' == msg.data.drop) {
          startAnimation('.ring-down-red .ring-down', 'my-animation-ring-down');
          startAnimation('.ring-down-red', 'my-animation-ring-down-x');
          startSound('ring');
        } else if ('ヒヒイロカネ' == msg.data.drop) {
          startAnimation('.hihi-down .ring-down', 'my-animation-ring-down');
          startAnimation('.hihi-down', 'my-animation-hihi-down-x');
          startAnimation('.hihi-down', 'my-animation-hihi-hakei');
          startSound('hihi');
        }
      } else if ("sand" in msg.data) {
        startAnimation('.sand-down-1 .ring-down', 'my-animation-ring-down');
        startAnimation('.sand-down-1', 'my-animation-ring-down-x');
        startSound('ring');
      } else if ("earring" in msg.data) {
        startAnimation('.earring-down-1 .ring-down', 'my-animation-ring-down');
        startAnimation('.earring-down-1', 'my-animation-ring-down-x');
        startSound('ring');
      } else if ("artifact" in msg.data) {
        startAnimation('.artifact-down-1 .ring-down', 'my-animation-ring-down');
        startAnimation('.artifact-down-1', 'my-animation-ring-down-x');
        startSound('ring');
      } else {
        startSound();
      }
      // スロット
      document.getElementById("slot-container")?.dispatchEvent(new CustomEvent("startSlot", {
        detail: {
          token: msg.data.token
        }
      }));
    }
};

function remakeTop(result) {
    if ("enemy_id" in result) {
      // 敵決定
      let enemy = "tally-target-" + result.enemy_id + "-";
      // 統計情報　今日
      if (document.getElementById(enemy + 'statistics-day-total')) {
        document.getElementById(enemy + 'statistics-day-total').innerText = document.getElementById(enemy + 'statistics-day-total').innerText.replace(/(\d+)/, function(match) {
          return parseInt(match, 10) + 1;
        });
        document.getElementById(enemy + 'statistics-day-blue').innerText = document.getElementById(enemy + 'statistics-day-blue').innerText.replace(/(\d+)([^\d]+)\(([\d\.]+)%\)/, function(match, blue, between, percentage) {
          let updatedBlue = parseInt(blue, 10) + (result.blue ? 1 : 0);
          let total = parseInt(document.getElementById(enemy + 'statistics-day-total').innerText.match(/(\d+)/), 10);
          let updatedPercentage = Math.round(10 * 100 * updatedBlue / total) / 10;
          return `${updatedBlue}${between}(${updatedPercentage}%)`;
        });
        document.getElementById(enemy + 'statistics-day-hihi').innerText = document.getElementById(enemy + 'statistics-day-hihi').innerText.replace(/(\d+)/, function(match) {
          return parseInt(match, 10) + (result.drop ? (result.drop.match(new RegExp("ヒヒイロカネ", "g")) || []).length : 0);
        });
      }

      // 最後のヒヒから
      if ("numa" in result && document.getElementById(enemy + 'numa')) {
        document.getElementById(enemy + 'numa-total').innerText = result.numa.total;
        document.getElementById(enemy + 'numa-blue').innerText = `${result.numa.blue}(${result.numa.blue_percent}%)`;
        document.getElementById(enemy + 'numa-days-times').innerText = `${result.numa.pass}`;
      }

      // 内訳
      let items = (result.drop ?? "金箱-" + result.enemy_id).split(",");
      let hihiCount = items.filter(item => "ヒヒイロカネ" == item).length;
      let redHihiCount = ((1 == hihiCount && (!result.blue || 2 == items.length)) || 2 == hihiCount) ? 1 : 0;
      items.forEach(item => {
        // 赤箱ヒヒチェック
        if ("ヒヒイロカネ" == item && redHihiCount > 0) {
          redHihiCount -= 1;
          return;
        }

        // カウント増加
        const detail = document.querySelector('#' + enemy + 'detail-count[value="' + item + '"]');
        if (detail) {
          detail.innerText = (Number(detail.innerText) + 1).toString();
        }
      });
      // 全体のカウント数計算
      let total = 0;
      Array.from(document.querySelectorAll('#' + enemy + 'detail-count')).map((elem) => {
        total += Number(elem.innerText);
      });
      // 各アイテムの割合計算
      Array.from(document.querySelectorAll('#' + enemy + 'detail-percent')).map((elem) => {
        elem.innerText = "(" + (Math.round(10 * 100 * Number(document.querySelector('#' + enemy + 'detail-count[value="' + elem.getAttribute('value') + '"]').innerText) / total) / 10).toString() + "%)";
      });

      // ヒヒランキング
      if ("ranking" in result && document.getElementById(enemy + "hihi")) {
        const ranking = document.getElementById(enemy + "hihi");
        while(ranking.firstChild) {
          ranking.removeChild(ranking.firstChild);
        }
        const tmpRanking = document.getElementById('template-ranking');
        ranking.appendChild(createElementFromTwigTemplateData(tmpRanking.innerText, {enemy: enemy, ranking: result.ranking}));
      }
      if ("ranking" in result && document.getElementById(enemy + "hihi-runner")) {
        const rankingRunner = document.getElementById(enemy + "hihi-runner");
        while(rankingRunner.firstChild) {
          rankingRunner.removeChild(rankingRunner.firstChild);
        }
        const tmpRunner = document.getElementById('template-runner');
        rankingRunner.appendChild(createElementFromTwigTemplateData(tmpRunner.innerText, {enemy: enemy, ranking: result.ranking}));
      }
    }

    // 耳飾り
    if ("earring" in result && document.getElementById("earring")) {
      Array.from(document.querySelectorAll(".earring-number")).forEach(artifact => {
        const [earring, element] = artifact.id.split("-");
        artifact.innerText = result.earring.details[element];
      });
    }

    // 砂
    if ("sand" in result && document.getElementById("earring")) {
      document.getElementById('sand-number').innerText = result.sand.today;
    }

    // アーティファクト
    if ("artifact" in result && document.getElementById("artifact")) {
      // テーブル
      Array.from(document.querySelectorAll(".artifact_number")).forEach(artifact => {
        const [element, weapon] = artifact.id.split("_");
        artifact.innerText = result.artifact.details[element][weapon] > 0 ? result.artifact.details[element][weapon] : "";
      });
      // 統計
      document.getElementById("artifact-today").innerText = result.artifact.today;
      document.getElementById("artifact-week").innerText = result.artifact.week;
    }

    // ポップオーバー
    reloadPopover();
}

// アニメーションを開始する
function startAnimation(target, classname) {
    Array.from(document.querySelectorAll(target)).forEach(element => {
        if (0 !== element.offsetWidth || 0 !== element.offsetHeight) {
            element.classList.add(classname);
        }
    });
}

// アニメーション終了時にアニメーションクラスを削除する
Array.from(document.getElementsByClassName('gbfdrop-icon')).forEach(element => {
    element.addEventListener('animationend', (e) => {
        e.target.classList.forEach((className) => {
            if (className.startsWith('my-animation-')) {
                e.target.classList.remove(className);
            }
        });
    });
});

[].slice.call(document.querySelectorAll('.ring-down,.ring-down-x')).map(function (ringDown) {
    ringDown.addEventListener('animationend', (e) => {
        var array = [];
        e.target.classList.forEach((className) => {
            if (className.startsWith('my-animation-')) {
                array.push(className);
            }
        });
        array.forEach((className) => {
            e.target.classList.remove(className);
        });
    });
});

function startSound(target = null) {
  const number = setting("wsSound");
  if (0 == number) {
    return;
  }

  let filename = `sound/drop${number}`;
  if (target) {
    filename += `_${target}`;
  }
  filename += '.mp3';

  const audio = new Audio(filename);
  const volume = setting("wsSoundVolume");
  audio.volume = volume / 100;
  audio.play();
}

function onSocketMessageNewsAll (args) {
    if ("add" == args[0].title) {
        onSocketMessageNewsAllAdd(args[0]);
    } else if ("update" == args[0].title) {
        onSocketMessageNewsAllUpdate(args[0]);
    }
}

function onSocketMessageNewsAllAdd(msg) {
    // チャット画面が閉じていれば強調表示して終わり
    if (document.getElementById('floatingMenuNews').classList.contains("notshow")) {
        // カウント
        const letter = document.getElementById("flObjNewsMarkLetter");
        letter.innerText = letter.innerText ? Number(letter.innerText) + 1 : 1;

        // 表示
        const mark = document.getElementById("flObjNewsMark");
        if (mark.classList.contains("notshow")) {
            mark.classList.toggle("notshow");
        }

        return;
    }

    // ニュースを追加
    const board = document.querySelector('.tab-content .tab-pane#home .newsBoard');
    addNewsAll(board, msg.data);
    board.lastChild.scrollIntoView({behavior: "smooth", block: "center"});
}

function onSocketMessageNewsAllUpdate(msg) {
    // チャット画面が閉じていれば何もしない
    if (document.getElementById('floatingMenuNews').classList.contains("notshow")) {
        return;
    }
    
    const parent = document.querySelector('.newsLine[data-id="' + msg.data.id + '"] .emotes');
    createEmote(parent, msg.data.emote, true);
}

function onSocketMessageHouchi (args) {
    if ("add" == args[0].title) {
        addRow(args[0].data);
    } else if ("update" == args[0].title) {
        updateRow(args[0].data);
    } else if ("delete" == args[0].title) {
        deleteRow(args[0].data);
    }
}