import { createElementFromTwigTemplateData } from './global.js';
import { runes } from 'runes2';

export function addRow(data)
{
    // 作成
    data.user.namefirst = runes(data.user.name).shift();
    console.log(data);
    const tmpRow = document.getElementById('template-row');
    const row = createElementFromTwigTemplateData(tmpRow.innerText, data);
    row.addEventListener("click", (event) => {
        const id = event.currentTarget.dataset.id;
        navigator.clipboard.writeText(id).then(() => {
            document.getElementById("toast-copy-id").innerText = id;
            new bootstrap.Toast(document.querySelector(".toast.copy")).show();
        });
    });

    // フォームに応じて表示を切り替える
    const battle = document.getElementById("targetBattle").value;
    const checked = document.getElementById("waitingOnly").checked;
    if ((battle && battle != houchiRow.dataset.battle) || (checked && "待機中" != houchiRow.dataset.status)) {
        row.classList.add("d-none");
    } else {
        row.classList.remove("d-none");
    }
    
    // 先頭に追加する
    document.getElementById("houchi-table").prepend(row);

    if ("none" != row.style.display) {
        // 自動コピー
        if (document.getElementById("autoCopy").checked && document.hasFocus()) {
            row.click();
        }

        // 音
        if (document.getElementById("soundOn").checked) {
            new Audio("/decision2.mp3").play();
        }
    }
}

export function updateRow(data)
{
    const row = document.querySelector('.houchi-row[data-id="' + data.roomId + '"]');
    console.log(data);
    if (row) {
        // まず消す
        row.parentNode.removeChild(row);

        // 作り直す
        data.user.namefirst = runes(data.user.name).shift();
        const tmpRow = document.getElementById('template-row');
        const rowNew = createElementFromTwigTemplateData(tmpRow.innerText, data);
        rowNew.addEventListener("click", (event) => {
            const id = event.currentTarget.dataset.id;
            navigator.clipboard.writeText(id).then(() => {
                document.getElementById("toast-copy-id").innerText = id;
                new bootstrap.Toast(document.querySelector(".toast.copy")).show();
            });
        });

        // フォームに応じて表示を切り替える
        const battle = document.getElementById("targetBattle").value;
        const checked = document.getElementById("waitingOnly").checked;
        if ((battle && battle != rowNew.dataset.battle) || (checked && "待機中" != rowNew.dataset.status)) {
            rowNew.classList.add("d-none");
        } else {
            rowNew.classList.remove("d-none");
        }

        // 先頭に追加する
        document.getElementById("houchi-table").prepend(rowNew);

        if (!rowNew.classList.contains("d-none")) {
            // 自動コピー
            if (document.getElementById("autoCopy").checked && document.hasFocus()) {
                rowNew.click();
            }
    
            // 音
            if (document.getElementById("soundOn").checked) {
                new Audio("/decision2.mp3").play();
            }
        }
    }
}

export function deleteRow(data)
{
    const row = document.querySelector('.houchi-row[data-id="' + data.roomId + '"]');
    if (row) {
        row.parentNode.removeChild(row);
    }
}