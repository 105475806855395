//import MainScene from './module/slotMainScene.js';

if(document.URL.match(/slot/)) {
    import('./module/slotMainScene.js')
        .then(MainScene => {
            var config = {
                type: Phaser.WEBGL,
                parent: 'slot-container',
                width: 800,
                height: 600,
                backgroundColor: '#000000',
                scale: {
                    mode: Phaser.Scale.FIT
                },
                audio: {
                    context: new window.AudioContext()
                },
                scene: [MainScene.MainScene]
            };
        
            var game = new Phaser.Game(config);
        });
}