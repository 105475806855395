import { createElementFromTwigTemplateData } from './module/global.js';

if(document.URL.endsWith('/record/artifact')) {
    // 初回タブ表示時にロードする
    window.addEventListener('load', () => {
        load("mine", "day");
    });

    // ロード（自分）
    function load(who, target)
    {
        const parent = document.getElementById("nav-" + who);

        // スピナー
        const spinnerPos = document.createElement("div");
        spinnerPos.setAttribute("class", "d-flex justify-content-center");
        const spinner = document.createElement("div");
        spinner.setAttribute("class", "spinner-border text-secondary");
        spinner.setAttribute("role", "status");
        spinnerPos.appendChild(spinner);
        parent.appendChild(spinnerPos);

        // 今日の日付
        let d = new Date();
        if (d.getHours() < 5) {
            d.setHours(d.getHours() - 5);
        }

        // API実行
        fetch("/record/artifact/" + who + "?targetDate=" + d.toLocaleDateString("sv-SE") + "&target=" + target, {
            method: "GET",
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            // 画面リセット
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }

            // テンプレート読み込み
            const tmpRecord = document.getElementById('template-record');
            parent.innerHTML = createElementFromTwigTemplateData(tmpRecord.innerText, {who: who, target: target}, true);

            // 日ボタン
            document.querySelector('.' + who + '-day').addEventListener('click', (event) => {
                if (!event.target.checked) {
                    return;
                }
                Array.from(document.querySelectorAll(".artifact_number")).forEach(artifact => {
                    artifact.innerText = artifact.dataset.day;
                });
            });

            // 週ボタン
            document.querySelector('.' + who + '-week').addEventListener('click', (event) => {
                if (!event.target.checked) {
                    return;
                }
                Array.from(document.querySelectorAll(".artifact_number")).forEach(artifact => {
                    artifact.innerText = artifact.dataset.week;
                });
            });

            // Datepicker
            var datepickerOptions = {
                endDate: new Date(),
                language: navigator.language,
                todayHighlight: true,
                autoclose: true,
                orientation: "bottom"
            };

            // day
            datepickerOptions.format = "yyyy-mm-dd";
            datepickerOptions.startDate = "2023-08-25";
            $(".input-group.date.day").datepicker(datepickerOptions);
            $(".input-group.date.day").datepicker("update", d.toLocaleDateString("sv-SE"));
            $(".input-group.date.day").datepicker().on("changeDate", () => {
                changeDataBody(who, "day");
            });
            const targetDateDay = document.getElementById("target-date-" + who + "-day").parentNode;
            new bootstrap.Collapse(targetDateDay, { toggle: false });

            // Datepicker切り替え
            changeDatepicker(who, target);

            // データボディ
            createDataBody(who, data, target);
            showDataBody(who, target, "tsuyobaha");
        })
        ;
    }

    // データボディ作成
    function createDataBody(who, data, target)
    {
        if ("mine" == who) {
            createDataBodyMine(data, target);
        } else if ("all" == who) {
            createDataBodyAll(data, target);
        }
    }

    // データボディ（自分）作成
    function createDataBodyMine(data, target)
    {
        // 日付
        let targetDate = null;
        if (document.getElementById("target-date-mine-" + target)) {
            targetDate = document.getElementById("target-date-mine-" + target).value.replaceAll("/", "-");
        }

        // データボディ作成
        const tmpDataBodyMine = document.getElementById('template-data-body-mine');
        const dataBody = createElementFromTwigTemplateData(tmpDataBodyMine.innerText, {target: target, targetDate: targetDate, artifact: data});
        document.getElementById("data-body-mine").appendChild(dataBody);
        new bootstrap.Collapse(dataBody, { toggle: false }).hide();

        // id
        let id = "data-body-mine-" + target;
        if (targetDate) {
            id = id + "-" + targetDate;
        }
    }

    // データボディ表示
    function showDataBody(who, target)
    {
        // targetDate取得
        let targetDate = "";
        if (document.getElementById("target-date-" + who + "-" + target)) {
            targetDate = document.getElementById("target-date-" + who + "-" + target).value;
        }

        // 対象のデータボディ取得
        let id = "data-body-" + who + "-" + target;
        if (targetDate) {
            id = id + "-" + targetDate.replaceAll("/", "-");
        }
        if ("slot" == who && "total" == target) {
            id = id + "-total";
        }
        const dataBodyTarget = document.getElementById(id);

        // 存在しなければ終わり
        if (!dataBodyTarget) {
            return false;
        }

        // すべてのデータボディ非表示
        const dataBodyParent = document.getElementById("data-body-" + who);
        Array.from(dataBodyParent.childNodes).forEach((db) => {
            bootstrap.Collapse.getInstance(db).hide();
        });

        // 対象のデータボディ表示
        bootstrap.Collapse.getInstance(dataBodyTarget).show();

        // 日ボタンを押す
        document.querySelector('.' + who + '-day').click();

        return true;
    }

    // データボディ切り替え
    function changeDataBody(who, target)
    {
        if (!showDataBody(who, target)) {
            // targetDate取得
            let targetDate = "";
            if (document.getElementById("target-date-" + who + "-" + target)) {
                targetDate = document.getElementById("target-date-" + who + "-" + target).value;
                if ("slot" == who) {
                    targetDate = targetDate + "-02";
                }
            }

            // API実行
            let url = "/record/artifact/" + who + "?target=" + target;
            if (targetDate) {
                url = url + "&targetDate=" + targetDate;
            }
            fetch(url, {
                method: "GET",
            })
            .then(r => {
                return r.json();
            })
            .then(d => {
                if ("slot" == who) {
                    createDataBodySlot(d, target);
                } else {
                    createDataBody(who, d, target);
                }
            })
            .finally(() => {
                showDataBody(who, target);
            })
            ;
        }
    }

    function changeDatepicker(who, target)
    {
        // 表示対象のDatepicker取得
        let datepickerTarget = null;
        if (document.getElementById("target-date-" + who + "-" + target)) {
            datepickerTarget = document.getElementById("target-date-" + who + "-" + target).parentNode;
        }

        // すべてのDatepicker非表示
        const targetDateParent = document.getElementById("target-date");
        Array.from(targetDateParent.children).forEach((dp) => {
            bootstrap.Collapse.getInstance(dp).hide();
        });

        // 対象のDatepicker表示
        if (datepickerTarget) {
            bootstrap.Collapse.getInstance(datepickerTarget).show();
        }
    }
}