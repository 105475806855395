import { createElementFromTwigTemplateData, reloadPopover } from './module/global.js';

if(document.URL.endsWith('/record')) {
    // 初回タブ表示時にロードする
    window.addEventListener('load', () => {
        load("mine", "day");
    });
    document.getElementById('nav-all-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('nav-all').childNodes.length && load("all", "day");
    });
    document.getElementById('nav-ranking-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('ranking-hihioneday').childNodes.length && loadHihiOneday();
    });
    document.getElementById('v-pills-ranking-shigoku-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('ranking-shigoku').childNodes.length && loadShigoku();
    });
    document.getElementById('v-pills-ranking-numa-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('ranking-numa').childNodes.length && loadNuma();
    });
    /*
    document.getElementById('nav-slot-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('v-pills-slot-month').childNodes.length && loadSlot("month");
    });
    document.getElementById('v-pills-slot-month-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('v-pills-slot-month').childNodes.length && loadSlot("month");
    });
    document.getElementById('v-pills-slot-total-tab').addEventListener('shown.bs.tab', () => {
        !document.getElementById('v-pills-slot-total').childNodes.length && loadSlot("total");
    });
    */

    // ロード（自分）
    function load(who, target)
    {
        const parent = document.getElementById("nav-" + who);

        // スピナー
        const spinnerPos = document.createElement("div");
        spinnerPos.setAttribute("class", "d-flex justify-content-center");
        const spinner = document.createElement("div");
        spinner.setAttribute("class", "spinner-border text-secondary");
        spinner.setAttribute("role", "status");
        spinnerPos.appendChild(spinner);
        parent.appendChild(spinnerPos);

        // API実行
        fetch("/record/" + who + "?target=" + target + "&enemyId=1", {
            method: "GET",
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            // 画面リセット
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }

            // テンプレート読み込み
            const tmpRecord = document.getElementById('template-record');
            parent.innerHTML = createElementFromTwigTemplateData(tmpRecord.innerText, {who: who, target: target}, true);

            // 敵タブ挿入
            const enemyInput = document.getElementById(who + "-input-enemy");
            Array.from(document.getElementById("dummy-input-enemy").options).forEach((option) => {
                enemyInput.appendChild(option.cloneNode(true));
            });

            // 敵タブ切り替え
            enemyInput.addEventListener('change', (event) => {
                changeDataBody(who, target);
            });

            // 対象タブ挿入
            const targetInput = document.getElementById(who + "-input-target");
            Array.from(document.getElementById("dummy-input-target").options).forEach((option) => {
                targetInput.appendChild(option.cloneNode(true));
            });

            // 対象タブ切り替え
            targetInput.addEventListener('change', (event) => {
                // Datepicker切り替え
                changeDatepicker(who, event.target.value);

                // データボディ切り替え
                changeDataBody(who, event.target.value);
            });

            // ポップオーバー
            reloadPopover();

            // Datepicker
            var datepickerOptions = {
                endDate: new Date(),
                language: navigator.language,
                todayHighlight: true,
                autoclose: true,
                orientation: "bottom"
            };
            let d = new Date();
            if (d.getHours() < 5) {
                d.setHours(d.getHours() - 5);
            }

            // day
            datepickerOptions.format = "yyyy-mm-dd";
            datepickerOptions.startDate = "2023-08-25";
            $(".input-group.date.day").datepicker(datepickerOptions);
            $(".input-group.date.day").datepicker("update", d.toLocaleDateString("sv-SE"));
            $(".input-group.date.day").datepicker().on("changeDate", () => {
                changeDataBody(who, "day");
            });
            const targetDateDay = document.getElementById("target-date-" + who + "-day").parentNode;
            new bootstrap.Collapse(targetDateDay, { toggle: false });

            // month
            datepickerOptions.format = "yyyy-mm";
            datepickerOptions.startDate = "2023-08";
            datepickerOptions.startView = 1;
            datepickerOptions.minViewMode = 1;
            $(".input-group.date.month").datepicker(datepickerOptions);
            $(".input-group.date.month").datepicker("update", d.toLocaleDateString("sv-SE"));
            $(".input-group.date.month").datepicker().on("changeDate", () => {
                changeDataBody(who, "month");
            });
            const targetDateMonth = document.getElementById("target-date-" + who + "-month").parentNode;
            new bootstrap.Collapse(targetDateMonth, { toggle: false });

            // Datepicker切り替え
            changeDatepicker(who, target);

            // つよばはのデータボディ
            createDataBody(who, data, target, "tsuyobaha");
            showDataBody(who, target, "tsuyobaha");
        })
        ;
    }

    // データボディ作成
    function createDataBody(who, data, target, enemy)
    {
        if ("mine" == who) {
            createDataBodyMine(data, target, enemy);
        } else if ("all" == who) {
            createDataBodyAll(data, target, enemy);
        }
    }

    // データボディ（自分）作成
    function createDataBodyMine(data, target, enemy)
    {
        // 日付
        let targetDate = null;
        if (document.getElementById("target-date-mine-" + target)) {
            targetDate = document.getElementById("target-date-mine-" + target).value.replaceAll("/", "-");
        }

        // データボディ作成
        const tmpDataBodyMine = document.getElementById('template-data-body-mine');
        const dataBody = createElementFromTwigTemplateData(tmpDataBodyMine.innerText, {target: target, enemy: enemy, targetDate: targetDate, data: data});
        document.getElementById("data-body-mine").appendChild(dataBody);
        new bootstrap.Collapse(dataBody, { toggle: false }).hide();

        // id
        let id = "data-body-mine-" + target + "-" + enemy;
        if (targetDate) {
            id = id + "-" + targetDate;
        }

        // グラフ
        const canvas = document.getElementById(id + "-chart");
        createChart(canvas, data);

        reloadPopover();
    }

    // データボディ（みんな）作成
    function createDataBodyAll(data, target, enemy)
    {
        // 日付
        let targetDate = null;
        if (document.getElementById("target-date-all-" + target)) {
            targetDate = document.getElementById("target-date-all-" + target).value.replaceAll("/", "-");
        }

        // データボディ作成
        const announce = document.getElementById("dummy-text1").innerText;
        const tmpDataBodyAll = document.getElementById('template-data-body-all');
        const dataBody = createElementFromTwigTemplateData(tmpDataBodyAll.innerText, {target: target, enemy: enemy, targetDate: targetDate, data: data, all_total_announce: announce});
        document.getElementById("data-body-all").appendChild(dataBody);
        new bootstrap.Collapse(dataBody, { toggle: false }).hide();

        // id作成
        let id = "data-body-all-" + target + "-" + enemy;
        if (targetDate) {
            id += "-" + targetDate;
        }

        // グラフ
        const canvas = document.getElementById(id + "-chart");
        createChart(canvas, data);

        reloadPopover();
    }

    // データボディ（スロット）作成
    function createDataBodySlot(data, target)
    {
        let targetDate = "";
        if (document.getElementById("target-date-slot-" + target)) {
            targetDate = document.getElementById("target-date-slot-" + target).value;
        }

        const dataBody = document.createElement("div");
        let id = "data-body-slot-" + target;
        if (targetDate) {
            id = id + "-" + targetDate.replaceAll("/", "-");
        }
        if ("total" == target) {
            id = id + "-total";
        }
        dataBody.setAttribute("id", id);
        dataBody.setAttribute("class", "collapse");

        const flex = document.createElement("div");
        flex.setAttribute("class", "d-flex flex-column border border-secondary overflow-auto h-100 mt-3");
        Object.keys(data).reverse().forEach((number) => {
            const row = document.createElement("div");
            row.setAttribute("class", "d-flex align-items-center py-2 border-bottom border-secondary border-1");
            const rank = document.createElement("div");
            rank.setAttribute("class", "col-2 gy-1 text-center");
            rank.innerText = (flex.childElementCount + 1).toString() + "位";
            row.appendChild(rank);
            const names = document.createElement("div");
            names.setAttribute("class", "col-8 gy-1 text-center");
            [0, 1, 2].map((userNumber) => {
                if (!data[number].users[userNumber]) {
                    return;
                }
                const name = document.createElement("div");
                name.setAttribute("class", "d-flex justify-content-center align-items-center gap-2");
                if (0 === flex.childElementCount) {
                    const icon = document.createElement("i");
                    icon.setAttribute("class", "fa-solid fa-crown");
                    name.appendChild(icon);
                }
                const a = document.createElement("a");
                a.setAttribute("href", "/profile/" + data[number].users[userNumber].id);
                a.innerText = data[number].users[userNumber].name;
                name.appendChild(a);
                names.appendChild(name);
            });
            if (data[number].users.length > 3) {
                const button = document.createElement("button");
                button.setAttribute("type", "button");
                button.setAttribute("class", "btn btn-outline-secondary btn-sm mt-1");
                button.setAttribute("data-bs-toggle", "popover");
                button.setAttribute("data-bs-placement", "bottom");
                let content = '';
                for (let i = 3; i < data[number].users.length; i++) {
                    content = content + "<div class='text-center'><a href='/profile/" + data[number].users[i].id + "'>" + data[number].users[i].name + "</a></div>";
                }
                button.setAttribute("data-bs-content", content);
                button.innerText = "他" + (data[number].users.length - 3).toString() + "名";
                names.appendChild(button);
            }
            row.appendChild(names);
            const count = document.createElement("div");
            count.setAttribute("class", "col-2 gy-1 text-center");
            count.innerText = number;
            row.appendChild(count);
            flex.appendChild(row);
        });
        dataBody.appendChild(flex);

        document.getElementById("data-body-slot-" + target).appendChild(dataBody);
        new bootstrap.Collapse(dataBody, { toggle: false }).hide();

        reloadPopover();
    }

    // データボディ表示
    function showDataBody(who, target, enemy = null)
    {
        // targetDate取得
        let targetDate = "";
        if (document.getElementById("target-date-" + who + "-" + target)) {
            targetDate = document.getElementById("target-date-" + who + "-" + target).value;
        }

        // 対象のデータボディ取得
        let id = "data-body-" + who + "-" + target;
        if (enemy) {
            id = id + "-" + enemy;
        }
        if (targetDate) {
            id = id + "-" + targetDate.replaceAll("/", "-");
        }
        if ("slot" == who && "total" == target) {
            id = id + "-total";
        }
        const dataBodyTarget = document.getElementById(id);

        // 存在しなければ終わり
        if (!dataBodyTarget) {
            return false;
        }

        // すべてのデータボディ非表示
        const dataBodyParent = document.getElementById("data-body-" + who);
        Array.from(dataBodyParent.childNodes).forEach((db) => {
            bootstrap.Collapse.getInstance(db).hide();
        });

        // 対象のデータボディ表示
        bootstrap.Collapse.getInstance(dataBodyTarget).show();

        return true;
    }

    // データボディ切り替え
    function changeDataBody(who, target)
    {
        let enemy = '';
        const elements = document.getElementById(who + "-input-enemy");
        for (let i = 0; i < elements.options.length; i++) {
            if (elements.options.item(i).selected) {
                enemy = elements.options.item(i).value;
                break;
            }
        }

        if (!showDataBody(who, target, enemy)) {
            // targetDate取得
            let targetDate = "";
            if (document.getElementById("target-date-" + who + "-" + target)) {
                targetDate = document.getElementById("target-date-" + who + "-" + target).value;
                if ("slot" == who) {
                    targetDate = targetDate + "-02";
                }
            }

            let enemyId = null;
            if ("tsuyobaha" == enemy) {
                enemyId = 1;
            } else if ("grande" == enemy) {
                enemyId = 2;
            } else if ("akasha" == enemy) {
                enemyId = 3;
            }

            // API実行
            let url = "/record/" + who + "?target=" + target;
            if (enemyId) {
                url = url + "&enemyId=" + enemyId;
            }
            if (targetDate) {
                url = url + "&targetDate=" + targetDate;
            }
            fetch(url, {
                method: "GET",
            })
            .then(r => {
                return r.json();
            })
            .then(d => {
                if ("slot" == who) {
                    createDataBodySlot(d, target);
                } else {
                    createDataBody(who, d, target, enemy);
                }
            })
            .finally(() => {
                showDataBody(who, target, enemy);
            })
            ;
        }
    }

    // グラフ作成
    function createChart(canvas, data)
    {
        return new Chart(canvas, {
            type: 'line',
            data: {
                labels: data.chart.label,
                datasets: Object.keys(data.chart.dataset).map((key, index) => {
                    return {
                        label: key,
                        data: data.chart.dataset[key],
                        borderColor: 0 === index ? "rgba(176,235,0,255)" : 1 === index ? "rgba(59,0,235,255)" : 2 == index ? "rgba(236,176,0,255)" : "",
                        backgroundColor: "rgba(0,0,0,0)"
                    };
                }),
            },
            options: {
                title: {
                    display: true
                },
                scales: {
                    yAxes: [{
                    ticks: {
                        suggestedMin: 0,
                        callback: function(value, index, values){
                        return  value;
                        }
                    },
                    gridLines: {
                        color: "#495057",
                    }
                    }],
                    xAxes: [{
                    gridLines: {
                        color: "#495057",
                    }
                    }]
                },
            },
            plugins: [{
                beforeDraw: function (c) {
                    var ctx = c.chart.ctx;
                    ctx.fillStyle = "#fff";
                    var xscale = c.scales["x-axis-0"];
                    var yscale = c.scales["y-axis-0"];
                    var high_top = yscale.getPixelForValue(35);
                    var low_top = yscale.getPixelForValue(0);
                    var low_height = yscale.getPixelForValue(-5) - low_top;
                }
            }]
        });
    }

    function changeDatepicker(who, target)
    {
        // 表示対象のDatepicker取得
        let datepickerTarget = null;
        if (document.getElementById("target-date-" + who + "-" + target)) {
            datepickerTarget = document.getElementById("target-date-" + who + "-" + target).parentNode;
        }

        // すべてのDatepicker非表示
        const targetDateParent = document.getElementById("target-date");
        Array.from(targetDateParent.children).forEach((dp) => {
            bootstrap.Collapse.getInstance(dp).hide();
        });

        // 対象のDatepicker表示
        if (datepickerTarget) {
            bootstrap.Collapse.getInstance(datepickerTarget).show();
        }
    }

    function loadRanking(parent, url, templateData)
    {
        // スピナー
        const spinnerPos = document.createElement("div");
        spinnerPos.setAttribute("class", "d-flex justify-content-center");
        const spinner = document.createElement("div");
        spinner.setAttribute("class", "spinner-border text-secondary");
        spinner.setAttribute("role", "status");
        spinnerPos.appendChild(spinner);

        // 親要素を空にしてスピナー追加
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
        parent.appendChild(spinnerPos);

        // API実行
        fetch(url, {
            method: "GET",
        })
        .then(r => {
            return r.json();
        })
        .then(d => {
            // 親要素を空にしてランキング追加
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
            parent.appendChild(createElementFromTwigTemplateData(templateData, {data: d}));

            reloadPopover();
        })
        ;
    }

    function loadHihiOneday()
    {
        const parent = document.getElementById("ranking-hihioneday");
        const tmpHihioneday = document.getElementById('template-hihioneday');
        loadRanking(parent, "/record/hihioneday", tmpHihioneday.innerText);
    }

    function loadShigoku()
    {
        const parent = document.getElementById("ranking-shigoku");
        const tmpShigoku = document.getElementById('template-shigoku');
        loadRanking(parent, "/record/shigoku", tmpShigoku.innerText);
    }

    function loadNuma()
    {
        const parent = document.getElementById("ranking-numa");
        const tmpNuma = document.getElementById('template-numa');
        loadRanking(parent, "/record/numa", tmpNuma.innerText);
    }

    // ロード（スロット）
    function loadSlot(target)
    {
        const parent = document.getElementById('v-pills-slot-' + target);

        // スピナー
        const spinnerPos = document.createElement("div");
        spinnerPos.setAttribute("class", "d-flex justify-content-center");
        const spinner = document.createElement("div");
        spinner.setAttribute("class", "spinner-border text-secondary");
        spinner.setAttribute("role", "status");
        spinnerPos.appendChild(spinner);
        parent.appendChild(spinnerPos);

        // targetDate取得
        let targetDate = "";
        if (document.getElementById("target-date-slot-" + target)) {
            targetDate = document.getElementById("target-date-slot-" + target).value;
        }
        
        // API実行
        let url = "/record/slot?target=" + target;
        if (targetDate) {
            url = url + "&targetDate=" + targetDate;
        }
        fetch(url, {
            method: "GET",
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            // 画面リセット
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }

            // テンプレート読み込み
            const tmpRecord = document.getElementById('template-record');
            parent.innerHTML = createElementFromTwigTemplateData(tmpRecord.innerText, {who: 'slot', target: target}, true);

            // Datepicker
            if ('month' == target) {
                var datepickerOptions = {
                    endDate: new Date(),
                    language: "ja",
                    todayHighlight: true,
                    autoclose: true,
                    orientation: "bottom"
                };
                datepickerOptions.format = "yyyy-mm";
                datepickerOptions.startDate = "2023-08";
                datepickerOptions.startView = 1;
                datepickerOptions.minViewMode = 1;
                $(".input-group.date").datepicker(datepickerOptions);
                let d = new Date();
                if (d.getHours() < 5) {
                    d.setHours(d.getHours() - 5);
                }
                $(".input-group.date").datepicker("update", d.toLocaleDateString("sv-SE"));
                $(".input-group.date").datepicker().on("changeDate", () => {
                    changeDataBody("slot", target);
                });
            }

            createDataBodySlot(data, target);
            showDataBody("slot", target);
        })
        ;
    }
}