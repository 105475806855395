/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/solid.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/brands.css';

import './js/global.js';
import './js/home.js';
import './js/record.js';
import './js/record_artifact.js';
import './js/setting.js';
import './js/profile.js';
import './js/houchi.js';
import './js/ws.js';
import './js/news.js';
import './js/slot.js';
import './js/event.js';