import { createElementFromTwigTemplateData } from './module/global.js';

if(document.URL.match(/profile/)) {
    document.getElementById("achivementModal").addEventListener("show.bs.modal", () => {
        // ロード済みなら何もしない
        if (document.getElementById("nameplate-table").childElementCount) {
            return;
        }

        // API実行
        fetch(location.pathname + "/achivement", {
            method: "GET",
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            const tmpAchivementTable = document.getElementById('template-achivement-table');
            document.getElementById("nameplate-table").appendChild(createElementFromTwigTemplateData(tmpAchivementTable.innerText, {target: "nameplate", data: data}));
            document.getElementById("nameplate-number").innerText = "(" + data.nameplate_count + "/" + data.nameplate.length + ")";
            document.getElementById("title-table").appendChild(createElementFromTwigTemplateData(tmpAchivementTable.innerText, {target: "title", data: data}));
            document.getElementById("title-number").innerText = "(" + data.title_count + "/" + data.title.length + ")";
            document.getElementById("background-table").appendChild(createElementFromTwigTemplateData(tmpAchivementTable.innerText, {target: "background", data: data}));
            document.getElementById("background-number").innerText = "(" + data.background_count + "/" + data.background.length + ")";
            
            if (data.secret) {
                const sec = document.createElement("div");
                sec.setAttribute("class", "text-end text-muted");
                sec.innerText = data.secret;
                document.getElementById("achivement-modal-body").appendChild(sec);
            }
        });
    });
}