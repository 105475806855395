if(document.URL.match(/houchi/)) {
    window.addEventListener('load', () => {
        // すべてのデータにクリックイベントを追加
        [].slice.call(document.querySelectorAll(".houchi-row")).map(function (houchiRow) {
            houchiRow.addEventListener("click", (event) => {
                const id = event.currentTarget.dataset.id;
                navigator.clipboard.writeText(id).then(() => {
                    document.getElementById("toast-copy-id").innerText = id;
                    new bootstrap.Toast(document.querySelector(".toast.copy")).show();
                });
            });
        });

        // データの有無で表示を切り替えるイベントを追加
        const table = document.getElementById("houchi-table");
        const obs = new MutationObserver(notFound);
        obs.observe(table, {childList: true});
    });

    document.getElementById("targetBattle").addEventListener("change", (event) => {
        changeForm();
    });

    document.getElementById("waitingOnly").addEventListener("change", (event) => {
        changeForm();
    });
    
    function changeForm()
    {
        const battle = document.getElementById("targetBattle").value;
        const checked = document.getElementById("waitingOnly").checked;

        [].slice.call(document.querySelectorAll(".houchi-row")).map(function (houchiRow) {
            if ((battle && battle != houchiRow.dataset.battle) || (checked && "待機中" != houchiRow.dataset.status)) {
                houchiRow.classList.add("d-none");
            } else {
                houchiRow.classList.remove("d-none");
            }
        });

        notFound();
    }

    function notFound()
    {
        if (document.querySelector(".houchi-table .houchi-row:not(.d-none)")) {
            document.getElementById("houchi-none").classList.add("d-none");
        } else {
            document.getElementById("houchi-none").classList.remove("d-none");
        }
    }
}