import Twig from 'twig/twig.min';

export function setting(name)
{
    const setting = document.getElementById('setting');
    if (!setting || !setting.dataset[name]) {
        return null;
    }
    return setting.dataset[name];
}

export function createElementFromTwigTemplateData(data, params, htmlFlag = false)
{
    // テンプレートデータからhtml作成
    const html = Twig.twig({data: data, allowInlineIncludes: true}).render(params);

    if (htmlFlag) {
        return html;
    }
    
    // htmlから要素作成
    const tempEl = document.createElement('div');
    tempEl.innerHTML = html;
    return tempEl.firstElementChild;
}

export function removePopover(event) {
    const popoverElm = document.getElementsByClassName("popover")[0]
    if (popoverElm) {
        const target = event.target
        const isPopoverInsideClicked = target.closest(".popover")
        if (!isPopoverInsideClicked) {
            const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
            const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                hidePopover(popoverTriggerEl);
            })
        }
    }
}

export function reloadPopover()
{
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        disposePopover(popoverTriggerEl);
        return new bootstrap.Popover(popoverTriggerEl, {html: true})
    })
}

export function reloadTooltip()
{
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {html: true}))
}

export function createPopover(element, delay = 0)
{
    const option = {html: true};
    if (delay) {
        option.delay = {show: delay, hide: 0};
    }
    new bootstrap.Popover(element, option);
}

export function hidePopover(element)
{
    const popover = bootstrap.Popover.getInstance(element);
    if (popover) {
        popover.hide();
    }
}

export function disposePopover(element)
{
    const popover = bootstrap.Popover.getInstance(element);
    if (popover) {
        popover.dispose();
    }
}