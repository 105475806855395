import { addNewsAll } from './module/news.js';

if (document.getElementById('floatingMenuNews')) {
    // ニュース画面表示
    document.getElementById('flObgToggleNews').addEventListener('click', () => {
        // 親要素のトグルを操作
        const flWrapper = document.getElementById('floatingMenuNews');
        if (!flWrapper.classList.toggle('notshow')) {
        // 開くとき、アクティブタブにタブ表示イベント発火
        const home = document.querySelector('#home-tab.active');
        if (home) {
            home.dispatchEvent(new Event('shown.bs.tab'));
        }

        // 開くとき、強調表示削除
        const mark = document.getElementById("flObjNewsMark");
        if (!mark.classList.contains("notshow")) {
            mark.classList.toggle("notshow");
        }
        }
    });

    // ニュース画面リサイズ（Y方向のみ）
    document.getElementById('newsResize').addEventListener('drag', (event) => {
    if (0 === event.clientY) {
        return;
    }
    const height = Math.max(window.innerHeight - event.clientY - 38, 300).toString() + "px";
    document.querySelector(':root').style.setProperty("--news-height", height)
    });

    // タブ表示時にロードする
    document.getElementById('home-tab').addEventListener('shown.bs.tab', () => {
    const lds = document.querySelectorAll('.tab-content .tab-pane#home .newsBoard .newsLine');
    const ld = lds.length > 0 ? lds[lds.length - 1].dataset.id : '';
    loadHome(ld);
    });

    // Enterで送信
    document.getElementById('post-input-home').addEventListener('keypress', (event) => {
        if (13 == event.keyCode) {
            document.getElementById('post-button-home').dispatchEvent(new Event('click'));
        }
    })
    
    // 　データ送信処理（みんな）
    document.getElementById('post-button-home').addEventListener('click', () => {
        const input = document.getElementById('post-input-home');
        const message = input.value;
    
        if (!message) {
        return;
        }
    
        input.value = '';
    
        // API実行
        fetch("/news/all", {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify({message: message}),
        })
        .then(res => {
        const lds = document.querySelectorAll('.tab-content .tab-pane#home .newsBoard .newsLine');
        const ld = lds.length > 0 ? lds[lds.length - 1].dataset.id : '';
        loadHome(ld);
        });
    });
}

// ロード処理（みんな）：最終更新日時が1分以上前であればロードする
function loadHome(ld=null)
{
    let url = "/news/all";
    if (ld) {
      url = url + "?from=" + ld;
    }

    // API実行
    fetch(url, {
        method: "GET",
    })
    .then(res => {
        return res.json();
    })
    .then(data => {
        // ニュースを追加
        const board = document.querySelector('.tab-content .tab-pane#home .newsBoard');
        data.forEach(d => {
            addNewsAll(board, d);
        });
        board.lastChild.scrollIntoView({behavior: "smooth", block: "center"});
    });
}