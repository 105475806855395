import { reloadTooltip } from './module/global.js';

if (document.URL.match(/setting/)) {
    // 初回タブ表示時にロードする
    document.querySelector('#general-tab').addEventListener('shown.bs.tab', () => {
        history.pushState(null, null, "#general");
    });
    document.querySelector('#account-tab').addEventListener('shown.bs.tab', () => {
        history.pushState(null, null, "#account");
    });
    document.querySelector('#logout-tab').addEventListener('shown.bs.tab', () => {
        history.pushState(null, null, "#logout");
    });

    // ハッシュ
    var hash = document.location.hash;
    if (hash) {
        const tabElement = document.querySelector('' + hash + '-tab');
        if (tabElement) {
            const tab = new bootstrap.Tab(tabElement);
            tab.show();
        }
    }

    // リアルタイム更新時のサウンド再生試聴ボタン
    document.getElementById('realtimeSoundPlayButton').addEventListener('click', function (e) {
        const number = document.getElementById('settings_general_form_realtimeSound').value;
        if (0 === number) {
            return;
        }

        let filename = `sound/drop${number}`;
        if (this.dataset.target) {
            filename += `_${this.dataset.target}`;
        }
        filename += '.mp3';
        
        const audio = new Audio(filename);
        const volume = document.getElementById('settings_general_form_realtimeSoundVolume').value;
        audio.volume = volume / 100;
        audio.play();

        if ('' == this.dataset.target) {
            this.dataset.target = 'ring';
        } else if ('ring' == this.dataset.target) {
            this.dataset.target = 'hihi';
        } else if ('hihi' == this.dataset.target) {
            this.dataset.target = '';
        }
    });

    // サウンド再生しないとボリュームをいじれず試聴ボタンを推せない
    if (Number(document.getElementById('settings_general_form_realtimeSound').value) === 0) {
        document.getElementById('settings_general_form_realtimeSoundVolume').disabled = true;
        document.getElementById('realtimeSoundPlayButton').disabled = true;
    }
    document.getElementById('settings_general_form_realtimeSound').addEventListener('change', (e) => {
        const disabled = Number(e.currentTarget.value) === 0;
        document.getElementById('settings_general_form_realtimeSoundVolume').disabled = disabled;
        document.getElementById('realtimeSoundPlayButton').disabled = disabled;
    });

    // フォーム初期値から更新がないとボタンを押せない
    var generalFormValue = [];
    function checkDefaultValue(id)
    {
        generalFormValue[id] = [];

        document.querySelectorAll('#' + id + ' .form-control,.form-select,.form-range').forEach((form) => {
            // 初期値保存
            generalFormValue[id][form.id] = form.value;
    
            // イベント追加
            form.addEventListener('input', () => {
                // フォーム全チェック
                let disabled = true;
                document.querySelectorAll('#' + id + ' .form-control,.form-select,.form-range').forEach((form) => {
                    // 初期値と異なるかチェック
                    if (form.value != generalFormValue[id][form.id]) {
                        disabled = false;
                    }
                });
                // ボタン状態更新
                document.querySelector('#' + id + ' button[type="submit"]').disabled = disabled;
            });
        });
    }

    checkDefaultValue('v-pills-general');
    checkDefaultValue('v-pills-account');

    reloadTooltip();
}