import { reloadPopover } from './module/global.js';
import html2canvas from 'html2canvas';

if ("/" == location.pathname) {
    // トップメニューのグラデーションの表示状態を更新
    const outerContainer = document.getElementById('top-menu-container');
    const innerContent = document.getElementById('top-menu-inner');
    const slideLeftButton = document.getElementById('slideLeft');
    const slideRightButton = document.getElementById('slideRight');
    let currentPosition = 0;  // 初期位置
    function updateGradient() {
        if (currentPosition < 0) {
            outerContainer.classList.add('show-left');  // 左端が途切れているとき
            slideLeftButton.style.opacity = 1;
            slideLeftButton.style.cursor = 'pointer';
        } else {
            outerContainer.classList.remove('show-left');  // 最初の要素が表示中
            slideLeftButton.style.opacity = 0;
            slideLeftButton.style.cursor = 'default';
        }
    
        if (currentPosition > -1 * (innerContent.clientWidth - outerContainer.clientWidth + 50)) {
            outerContainer.classList.add('show-right');  // 右端が途切れているとき
            slideRightButton.style.opacity = 1;
            slideRightButton.style.cursor = 'pointer';
        } else {
            outerContainer.classList.remove('show-right');  // 最後の要素が表示中
            slideRightButton.style.opacity = 0;
            slideRightButton.style.cursor = 'default';
        }
    }

    // トップメニューを左にスライド
    function slideLeft() {
        if (currentPosition < 0) {
            currentPosition = Math.min(currentPosition + outerContainer.clientWidth * 0.3, 0);
            innerContent.style.transform = `translateX(${currentPosition}px)`;
            updateGradient();
        }
    }

    // トップメニューを右にスライド
    function slideRight() {
        if (currentPosition > -1 * (innerContent.clientWidth - outerContainer.clientWidth + 50)) {
            currentPosition = Math.max(currentPosition - outerContainer.clientWidth * 0.3, -1 * (innerContent.clientWidth - outerContainer.clientWidth + 50));
            innerContent.style.transform = `translateX(${currentPosition}px)`;
            updateGradient();
        }
    }

    // 左スライドボタン
    document.getElementById('slideLeft').addEventListener('click', () => {
        slideLeft();
    });
    
    // 右スライドボタン
    document.getElementById('slideRight').addEventListener('click', () => {
        slideRight();
    });

    // トップメニューのホイール操作
    outerContainer.addEventListener('wheel', (event) => {
        event.preventDefault();
        if (event.deltaY < 0) {
            slideLeft();
        } else {
            slideRight();
        }
    }, { passive: false });

    // ウインドウサイズを変更したらスライドボタンを更新する
    window.addEventListener('resize', () => {
        updateGradient();
    });

    updateGradient();

    // プレゼント一括受け取りボタン
    document.getElementById("present-get-all-button").addEventListener("click", (event) => {
        // スピナー
        const innerHTML = event.target.innerHTML;
        event.target.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>';

        // プレゼント個別受け取りAPI
        fetch("/present/all", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
        })
        .then(res => {
            if (!res.ok) {
                console.error('response.ok:', res.ok);
                console.error('esponse.status:', res.status);
                console.error('esponse.statusText:', res.statusText);
                throw new Error(res.statusText);
            }

            const parent = document.querySelector(".present-list");

            // 一括受取ボタンをdisabled
            document.getElementById("present-get-all-button").disabled = true;

            // プレゼント数表示を削除
            const presentNumber = document.getElementById("present-number");
            if (presentNumber) {
                presentNumber.remove();
            }

            // プレゼント要素を削除
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }

            // メッセージ
            new bootstrap.Toast(document.querySelector(".toast.get-present")).show();
        })
        .catch(error => {
        })
        .finally(() => {
            event.target.innerHTML = innerHTML;
        });
    });

    // プレゼント個別受け取りボタン
    [].slice.call(document.querySelectorAll(".present-get-button")).map((target) => {
        target.addEventListener("click", () => {
            // スピナー
            target.innerHTML = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';

            // プレゼント個別受け取りAPI
            fetch("/present/" + target.dataset.presentId, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
            })
            .then(res => {
                if (!res.ok) {
                    console.error('response.ok:', res.ok);
                    console.error('esponse.status:', res.status);
                    console.error('esponse.statusText:', res.statusText);
                    throw new Error(res.statusText);
                }

                const present = target.closest(".present-one");
                const parent = present.closest(".present-list");

                // 最後のプレゼントなら一括受取ボタンをdisabled
                if (parent.childElementCount == 1) {
                    document.getElementById("present-get-all-button").disabled = true;
                }

                // プレゼント数表示を更新
                const presentNumber = document.getElementById("present-number");
                if (presentNumber) {
                    presentNumber.innerText = parent.childElementCount - 1;

                    // 0なら削除
                    if (presentNumber.innerText == 0) {
                        presentNumber.remove();
                    }
                }

                // プレゼント要素を削除
                present.remove();

                // メッセージ
                new bootstrap.Toast(document.querySelector(".toast.get-present")).show();
            })
            .catch(error => {
            });
        });
    });

    // スクショモーダルを開いたらスクショ作成
    document.getElementById('screenshotModal').addEventListener('shown.bs.modal', async () => {
        [].slice.call(document.querySelectorAll(".ranking-user-name,.ranking-runner-user-name")).map((target) => {
            target.classList.add("d-none");
            target.classList.add("d-sm-none");
        });
        [].slice.call(document.querySelectorAll(".tab-pane.show.active .screenshot-target")).map(async (target) => {
            await getScreenShot(target);
        });
        [].slice.call(document.querySelectorAll(".ranking-user-name,.ranking-runner-user-name")).map((target) => {
            target.classList.remove("d-none");
            target.classList.remove("d-sm-none");
        });
    });

    // スクショ取得
    async function getScreenShot (src)
    {
        html2canvas(src, {backgroundColor: "#212529"}).then(async function (canvas) {
            // 加工
            const ctx = canvas.getContext("2d");
            ctx.resetTransform();
            //// ロゴ読み込み
            const loadImage = async (src) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve(img);
                    img.onerror = (e) => reject(e);
                    img.src = src;
                });
            };
            const image = await loadImage("/logo_48_2.png");
            //// 画像描画
            ctx.globalAlpha = 0.4;
            ctx.drawImage(image, canvas.width - image.width, canvas.height - image.height);

            // carousel-inner
            const inner = document.getElementById("scrrenshot-modal-carousel-inner");
            const item = document.createElement("div");
            item.setAttribute("class", "carousel-item");
            if (!inner.childElementCount) {
                item.classList.add("active")
            }
            const img = document.createElement("img");
            img.setAttribute("class", "d-block");
            if (canvas.width > canvas.height) {
                img.classList.add("w-100");
            } else {
                item.classList.add("h-100");
                img.classList.add("h-100");
                img.classList.add("mx-auto");
            }
            img.setAttribute("src", canvas.toDataURL());
            item.appendChild(img);
            
            // スピナーを消してコントロールを表示
            if (!inner.childElementCount) {
                document.getElementById("scrrenshot-modal-spinner").classList.add("d-none");
                document.querySelector(".carousel").classList.remove("d-none");
            }

            // 追加
            inner.appendChild(item)
        });
    }

    // スクショコピーボタン
    document.getElementById("screenshot-copy-btn").addEventListener("click", () => {
        const img = document.querySelector(".carousel-item.active img");
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
    
        // Canvas から Blob オブジェクトを生成
        canvas.toBlob(async (blob) => {
            // 画像データをクリップボードに書き込む
            const item = new ClipboardItem({
                'image/png': blob
            });
            navigator.clipboard.write([item]);

            // Toast
            new bootstrap.Toast(document.querySelector(".toast.screenshot-copy")).show();

            // モーダルを閉じる
            bootstrap.Modal.getInstance(document.getElementById('screenshotModal')).toggle();
        });
    });

    // スクショモーダルを閉じたらスクショを消す
    document.getElementById('screenshotModal').addEventListener('hidden.bs.modal', (event) => {
        const inner = document.getElementById("scrrenshot-modal-carousel-inner");
        while (inner.firstChild) {
            inner.removeChild(inner.firstChild);
        }
        document.getElementById("scrrenshot-modal-spinner").classList.remove("d-none");
        document.querySelector(".carousel").classList.add("d-none");
        document.querySelectorAll(".carousel-indicators button").forEach((element) => {
            element.classList.remove("active");
            element.removeAttribute("aria-current");
        })
        document.querySelector(".carousel-indicators button[data-bs-slide-to='0']").classList.add("active");
        document.querySelector(".carousel-indicators button[data-bs-slide-to='0']").setAttribute("aria-current", "true");
    });

    // アーティファクト展開ボタン
    /*
    document.getElementById('artifact-button').addEventListener('click', (event) => {
        document.getElementById('artifact-oneliner').classList.add('d-none');
        document.getElementById('artifact-detail').classList.remove('d-none');
        document.getElementById('artifact-week').classList.remove('d-none');
        event.currentTarget.classList.add('d-none');
    });
    */

    // youtubeサムネイルクリックイベント
    document.querySelectorAll('.youtubeThumbnail').forEach(thumbnail => {
        thumbnail.addEventListener('click', function(event) {
            var videoId = event.target.parentNode.dataset.videoId;
            var num = event.target.parentNode.dataset.num;

            // 埋め込み動画作成
            var player = new YT.Player('youtube-' + num, {
                videoId: videoId,
                events: {
                    'onReady': onPlayerReady,
                }
            });
        });
    });
    document.querySelectorAll('.youtubeThumbnailMark').forEach(mark => {
        mark.addEventListener('click', function(event) {
            var num = event.target.dataset.num;
            const thumbnail = document.querySelector('#youtube-' + num + ' img');
            if (thumbnail) {
                console.log(thumbnail);
                thumbnail.click();
            }
        });
    });

    function onPlayerReady(event) {
        // youtubeカルーセルを止める
        const ytc = document.querySelector('#youtubeCarousel');
        if (ytc) {
            const ytCarousel = new bootstrap.Carousel(ytc);
            ytCarousel.pause();
        }

        // 動画再生
        event.target.playVideo();
    }

    // youtubeカルーセルを動かす
    const ytc = document.querySelector('#youtubeCarousel');
    if (ytc) {
        const ytCarousel = new bootstrap.Carousel(ytc);
        ytCarousel.cycle();
    }

    // 占いカード初期状態
    const omikuji = getOmikujiToday();
    if (omikuji) {
        // すべて裏返す
        Array.from(document.querySelectorAll('.omikuji-card')).forEach (element => {
            const type = omikuji.result.cards[Number(element.dataset.number) - 1];
            element.classList.add('is-flipped');
            element.classList.add(`card-type-${type}`);
            element.querySelector('.card-back').innerHTML = document.getElementById(`template-omikuji-${type}`).innerHTML;
        });

        // 選択したカード
        document.querySelector(`.omikuji-card:nth-child(${omikuji.result.selected})`).classList.add('is-selected');
    }

    // 占いカードクリック処理
    Array.from(document.querySelectorAll('.omikuji-card')).forEach(element => {
        element.addEventListener('click', (e) => {      
            // 今日の分があるなら何もしない
            if (getOmikujiToday()) {
                return;
            }

            // スピナー
            const spinnerPos = document.createElement("div");
            spinnerPos.setAttribute("class", "d-flex justify-content-center");
            const spinner = document.createElement("div");
            spinner.setAttribute("class", "spinner-border text-secondary");
            spinner.setAttribute("role", "status");
            spinnerPos.appendChild(spinner);
            e.currentTarget.querySelector('.card-spinner').appendChild(spinnerPos);

            // 抽選
            const choices = [
                { num: 1, weight: 30 }, // 吉
                { num: 2, weight: 30 }, // 小吉
                { num: 3, weight: 15 }, // 凶
                { num: 4, weight: 15 }, // 中吉
                { num: 5, weight: 5 },  // 大凶
                { num: 6, weight: 6 },  // 大吉
            ];
        
            // 重み付きリストを作成
            let weightedList = [];
            choices.forEach(({ num, weight }) => {
                weightedList.push(...Array(weight).fill(num));
            });
        
            // シャッフルして重複なしで3つ選択
            const selected = new Set();
            while (selected.size < 3) {
                const randomIndex = Math.floor(Math.random() * weightedList.length);
                selected.add(weightedList[randomIndex]);
        
                // 選んだ数字をリストから削除
                weightedList = weightedList.filter(n => n !== weightedList[randomIndex]);
            }

            // 保存
            localStorage.setItem('omikuji', JSON.stringify({
                date: getOmikujiDate(),
                result: {
                    cards: Array.from(selected),
                    selected: e.currentTarget.dataset.number,
                },
            }));

            e.currentTarget.classList.add('is-selected');

            // すべて裏返す
            Array.from(document.querySelectorAll('.omikuji-card')).forEach (element => {
                const type = Array.from(selected)[Number(element.dataset.number) - 1];
                element.classList.add('is-flipped');
                element.classList.add(`card-type-${type}`);
                element.querySelector('.card-back').innerHTML = document.getElementById(`template-omikuji-${type}`).innerHTML;

                // 選んだもの以外はディレイ
                if (e.currentTarget != element) {
                    element.classList.add('is-delay');
                }
            });
        })
    });

    function getOmikujiToday() {
        // データ取得
        const omikuji = JSON.parse(localStorage.getItem('omikuji'));
            
        // 今日の分を返す
        const today = getOmikujiDate();
        if (omikuji && omikuji['date'] == today) {
            return omikuji;
        }

        return null;
    }

    function getOmikujiDate() {
        const now = new Date();
        const offset = 5;
        now.setHours(now.getHours() - offset);
    
        return now.toDateString();
    }

    // ポップオーバー
    reloadPopover();
}