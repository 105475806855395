import { createPopover, disposePopover } from './global.js';
import { runes } from 'runes2';

export function addNewsAll(parent, data)
{
    const row = document.createElement('div');
    const rowBody = document.createElement('div');
    const rowEmote = document.createElement('div');
    const icon = document.createElement('div');
    const message = document.createElement('div');
    message.setAttribute("class", "newsLineMessage");
    message.textContent = data.message;
    const date = document.createElement('div');
    date.setAttribute("class", "newsLineDate text-center");
    date.innerHTML = data.date.replace(' ', '<br>');
    const emote = document.createElement('div');
    emote.setAttribute("class", "newsLineEmote text-center");
    emote.innerHTML = '<i class="fa-regular fa-face-smile fa-xl"></i>';
    emote.addEventListener('click', (event) => {
        event.stopPropagation();

        // 他にホバーされてる行があればなにもしない
        if (document.querySelector(".newsLine.hovered")) {
            return;
        }

        // 絵文字マート作成
        const pickerOptions = {
            categories: ["frequent", "people", "nature", "foods", "activity", "places", "objects", "symbols"],
            emojiButtonSize: 36,
            emojiSize: 30,
            locale: "ja",
            onClickOutside: (e) => {
                const picker = document.querySelector("#flObgToggleNewsEmoji em-emoji-picker");

                // 行のホバー状態を解除する
                document.querySelector('.newsLine[data-id="' + picker.dataset.target + '"]').classList.toggle("hovered");

                // 自身を消す
                picker.parentNode.removeChild(picker);
            },
            onEmojiSelect: (e) => {
                const picker = document.querySelector("#flObgToggleNewsEmoji em-emoji-picker");

                // API実行
                fetch("/news/" + picker.dataset.target + "/emote", {
                  method: "PUT",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({codePoint: e.unified}),
                })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    // 行のホバー状態を解除する
                    document.querySelector('.newsLine[data-id="' + picker.dataset.target + '"]').classList.toggle("hovered");

                    // 自身を消す
                    picker.parentNode.removeChild(picker);
                });
            },
            previewPosition: "none",
        };
        const picker = new EmojiMart.Picker(pickerOptions);
        picker.dataset.target = event.currentTarget.parentNode.parentNode.dataset.id;
        const emoji = document.getElementById("flObgToggleNewsEmoji");
        emoji.firstElementChild.appendChild(picker);

        // 絵文字マート位置決定
        const emojiRect = emoji.getBoundingClientRect();
        const rect = event.target.parentNode.getBoundingClientRect();
        const top = picker.clientHeight - rect.top + emojiRect.top;
        emoji.firstElementChild.style.top = (-1 * top).toString() + "px";
        if (event.target.parentNode.parentNode.classList.contains("mine")) {
            const left = rect.left - picker.clientWidth - emojiRect.left;
            emoji.firstElementChild.style.left = left.toString() + "px";
        } else {
            const left = rect.right - emojiRect.left;
            const leftMax = document.documentElement.clientWidth - picker.clientWidth - emojiRect.left;
            emoji.firstElementChild.style.left = Math.min(left, leftMax).toString() + "px";
        }

        // この行をホバー状態にする
        event.currentTarget.parentNode.parentNode.classList.toggle("hovered");
    });

    if (data.user) {
        if (data.user.id == document.getElementById('useruuid').value) {
            // 自分のメッセージ
            row.setAttribute("class", "newsLine mine");
            row.setAttribute("data-id", data.id);
            rowBody.setAttribute("class", "newsLineBody d-flex justify-content-end align-items-center w-100");
            icon.setAttribute("class", "news-icon news-icon-user icon-" + data.user.nameplate + " mx-1");
            icon.setAttribute("title", data.user.name);
            const a = document.createElement('a');
            a.setAttribute("class", "text-decoration-none");
            a.setAttribute("href", "/profile/" + data.user.id);
            const div = document.createElement('div');
            div.setAttribute("class", "d-flex justify-content-center align-items-center h-100");
            const letter = document.createElement('div');
            letter.setAttribute("class", "news-icon-letter");
            letter.innerText = runes(data.user.name).shift();
            div.appendChild(letter);
            a.appendChild(div);
            icon.appendChild(a);
            rowBody.appendChild(emote);
            rowBody.appendChild(date);
            rowBody.appendChild(message);
            rowBody.appendChild(icon);
            row.appendChild(rowBody);
            rowEmote.setAttribute("class", "emotes d-flex justify-content-end align-items-center mb-1 w-100");
            createEmote(rowEmote, data.emote);
            row.appendChild(rowEmote);
        } else {
            // 他人のメッセージ
            row.setAttribute("class", "newsLine notMine");
            row.setAttribute("data-id", data.id ?? " ");
            rowBody.setAttribute("class", "newsLineBody d-flex align-items-center w-100");
            icon.setAttribute("class", "news-icon news-icon-user icon-" + data.user.nameplate + " mx-1");
            icon.setAttribute("title", data.user.name);
            const a = document.createElement('a');
            a.setAttribute("class", "text-decoration-none");
            a.setAttribute("href", "/profile/" + data.user.id);
            const div = document.createElement('div');
            div.setAttribute("class", "d-flex justify-content-center align-items-center h-100");
            const letter = document.createElement('div');
            letter.setAttribute("class", "news-icon-letter");
            letter.innerText = runes(data.user.name).shift();
            div.appendChild(letter);
            a.appendChild(div);
            icon.appendChild(a);
            rowBody.appendChild(icon);
            rowBody.appendChild(message);
            rowBody.appendChild(date);
            rowBody.appendChild(emote);
            row.appendChild(rowBody);
            rowEmote.setAttribute("class", "emotes d-flex justify-content-start align-items-center mb-1 w-100");
            createEmote(rowEmote, data.emote);
            row.appendChild(rowEmote);
        }
    } else {
        // システムメッセージ
        row.setAttribute("class", "newsLine notMine");
        row.setAttribute("data-id", data.id ?? " ");
        rowBody.setAttribute("class", "newsLineBody d-flex align-items-center w-100");
        icon.setAttribute("class", "news-icon mx-1");
        icon.setAttribute("title", "システムメッセージ");
        const div = document.createElement('div');
        div.setAttribute("class", "d-flex justify-content-center align-items-center h-100");
        const img = document.createElement('img');
        img.setAttribute("src", "/news_icon.png");
        div.appendChild(img);
        icon.appendChild(div);
        rowBody.appendChild(icon);
        rowBody.appendChild(message);
        rowBody.appendChild(date);
        rowBody.appendChild(emote);
        row.appendChild(rowBody);
        rowEmote.setAttribute("class", "emotes d-flex justify-content-start align-items-center mb-1 w-100");
        createEmote(rowEmote, data.emote);
        row.appendChild(rowEmote);
    }

    parent.appendChild(row);
}

export function createEmote(parent, data, refresh = false)
{
  if (refresh) {
      while(parent.firstElementChild) {
          parent.removeChild(parent.firstElementChild);
      }
  }

  const userUuid = document.getElementById('useruuid').value;

  Object.keys(data).forEach((codePoint) => {
      const emoteCell = document.createElement('div');
      if (-1 != data[codePoint].map(user => user["id"]).indexOf(userUuid)) {
          emoteCell.setAttribute("class", "emojiCell alert alert-info d-flex justify-content-center p-1 mb-0 gap-1");
      } else {
          emoteCell.setAttribute("class", "emojiCell alert alert-dark d-flex justify-content-center p-1 mb-0 gap-1");
      }
      emoteCell.setAttribute("data-bs-toggle", "popover");
      emoteCell.setAttribute("data-bs-placement", "top");
      emoteCell.setAttribute("data-bs-trigger", "hover");
      const content = document.createElement('div');
      content.setAttribute("class", "d-flex align-items-center gap-1");
      const contentEmoji = document.createElement('div');
      contentEmoji.setAttribute("class", "fs-1");
      contentEmoji.innerText = String.fromCodePoint(...codePoint.split("-").map(code => "0x" + code));
      content.appendChild(contentEmoji);
      const contentText = document.createElement('div');
      contentText.setAttribute("class", "fs-6");
      let text = data[codePoint].slice(0, 3).map(user => user["name"]).join(", ");
      if (data[codePoint].length > 3) {
          text = text + "と他" + (data[codePoint].length - 3).toString() + "名";
      }
      text = text + "にエモートされました";
      contentText.innerText = text;
      content.appendChild(contentText);
      emoteCell.setAttribute("data-bs-content", content.outerHTML);
      const emoji = document.createElement('div');
      emoji.innerText = String.fromCodePoint(...codePoint.split("-").map(code => "0x" + code));
      emoteCell.appendChild(emoji);
      const count = document.createElement('div');
      count.innerText = data[codePoint].length;
      emoteCell.appendChild(count);
      emoteCell.addEventListener('click', (event) => {
          disposePopover(event.currentTarget);

          // API実行
          fetch("/news/" + event.currentTarget.parentNode.parentNode.dataset.id + "/emote", {
              method: "PUT",
              headers: {
                  "Content-type": "application/json",
              },
              body: JSON.stringify({codePoint: event.currentTarget.firstElementChild.innerText.codePointAt(0).toString(16)}),
          })
          .then(res => {
              return res.json();
          })
          .then(data => {
          });
      });
      parent.appendChild(emoteCell);

      // ポップオーバー
      createPopover(emoteCell, 500);
  });
}